import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {

    padding: "30px 0 0 0",
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "100%",
  },

  logoWrapper: {
    position: "relative",
    aspectRatio: "1.6",
    width: "148px",
    height: "91px",
    marginTop: "0px",
    marginBottom: "0px",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "90px",
      height: "57px",
      marginRight: "15px"
    },

  },

  nameCompany: {
    /* background:"pink", */
    // width:"350px",
    /*     [`@media (max-width: ${theme.breakpoints.md}px)`]:{
          height
        } */
  },

  // Subscribe
  subscribe: {
    position: "relative",

    minWidth: "100%",
    // aspectRatio: "8.3",
    height: 161,

    // "&:before": {
    //   background: `url('${bg.src}') no-repeat center`,
    //   backgroundSize: "cover",

    //   content: "''",
    //   display: "block",
    //   position: "absolute",
    //   left: 0,
    //   top: 0,
    //   width: "100%",
    //   height: "100%",
    //   opacity: "0.4",
    // },
  },
  theBoat_a: {
    position: "relative",

    minWidth: "100%",
    // aspectRatio: "8.3",
    height: 150,
    /* background:"gray" */
  },
  wave: {
    overflow: "hidden",
    minWidth: "100%",
    aspectRatio: "4.5",
    height: 150,

    display: "block",
    position: "absolute",
    left: 0,
    top: 75,
  },

  theBoat: {
    overflow: "hidden",
    minWidth: "100%",
    /* aspectRatio: "4.5", */
    height: 100,
    objectFit: "contain",

    display: "block",
    position: "relative",
    right: "39%",
    top: 40,
  },



  bgSubscribe: {
    overflow: "hidden",
    minWidth: "100%",
    // aspectRatio: "8.37",
    height: 30,
    /* opacity: "0.4", */
    display: "block",
    position: "absolute",
    bottom: -15,
    backgroundColor: "",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: 27
    }

  },
  subscribeContent: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "end",
    //  /*  left: 80, */
  },

  info: {
    display: "flex",
    flexDirection: "column",

    minWidth: "100%",
    background: "#12854F",
    color: "#FFF",
  },
  infoBody: {
    width: "100%",
    padding: "30px 0 20px 0",

  },
  infoText: {
    transition: "0.3s",

    "&:hover": {
      color: theme.colors.main[5],
    },
    /* wordSpacing:"3px", */
  },
  infoText1: {
    marginLeft: "15px",
    "&::-moz-selection": {
      background: "#000",
    },

    "&::selection": {
      background: "#000",
    },
  },
  infoText2: {
    "&::-moz-selection": {
      background: "#000",
    },

    "&::selection": {
      background: "#000",
    },
  },
  infoBottom: {
    padding: "20px 0 25px",

    color: "#999999",
    fontWeight: "400",
    fontSize: 14,
  },

  contactItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px"
  },

  emailItem: {
    display: "flex",
    flexDirection: "column",

  },
  icon: {
    marginRight: 10,
    fontSize: 18,
    /* color: theme.colors.main[5], */
  },
  smallIcon: {
    /* marginRight: 2, */
    /* marginBottom: 30, */
    fontSize: 38,
    /* color: theme.colors.main[5], */
  },

}));

export default useStyles;
