import { flattenObj } from "@/utils/lodash";

const dmnvtKeys = {
  all: [{ scope: "ecomproductlines", limit: 200 }],
  count: (options) => [
    {
      scope: "ecomproductlines",
      type: "count",
      ...flattenObj(options || {}),
    },
  ],
  list: (options) => [
    {
      scope: "ecomproductlines",
      type: "list",
      ...flattenObj(options || {}),
    },
  ],
  detail: (slug) => [{
    scope: "ecomproductlines",
    type: "detail",
    slug
  }],
};

export default dmnvtKeys;
